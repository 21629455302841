<template>
    <div class="footer clearfix">
        <div class="footer-box">
            <div class="footer-text">联系我们：</div>
            <div class="footer-text">电话:&nbsp;010-63691476 1799965</div>
            <div class="footer-text">邮箱:&nbsp;wlb@cctaw.cn</div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PageFooter',
    data () {
        return {

        }
    }
}
</script>
<style lang="less" scoped>

.footer {
    width: 100%;
    height: 70px;
    background: #2A2F3C;
    position: relative;
    bottom: 0;
    .footer-text {
        float: left;
        line-height: 70px;
        font-size: 14px;
        color: #FFFFFF;
        margin: 0 30px
    }
    .footer-box {
        position: absolute;
        margin-left: 50%;
        transform: translateX(-50%);
    }
}
</style>