












































interface Idialog {
  show: boolean;
  loading: boolean;
  data: any;
  rules?: any;
}
import Cookies from 'js-cookie';

import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
import { IndexStore } from '@/store/module/index';

import md5 from 'js-md5';
import { AdminUpdatePwd, GetUserInfo, LoginAdminLogout } from '@/http/api/LoginApi';

@Component
export default class Header extends Vue {
  // get uederInfo () {}
  @Prop({ default: 60 }) private height!: number 
  @Prop({ default: 2020 })   private width!:number


  @Ref() private passwordForm!: HTMLDataElement;
  @Ref() private perfectForm!: HTMLDataElement;
  get userName() {
    return IndexStore.userInfo.nikeName;
  }
  // 修改密码
  private setPassword: Idialog = {
    show: false,
    loading: false,
    data: {
      oPassword: '',
      newPassword: '',
      newPasswords: '',
    },
    rules: {
      oPassword: [
        { required: true, message: '请输入旧密码', trigger: 'blur' },
        { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/, message: '8-16位数字字母组合', trigger: 'blur' },
      ],
      newPassword: [
        { required: true, message: '请输入新密码', trigger: 'blur' },
        { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/, message: '8-16位数字字母组合', trigger: 'blur' },
      ],
      newPasswords: [
        { required: true, message: '请输入确认新密码', trigger: 'blur' },
        { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/, message: '8-16位数字字母组合', trigger: 'blur' },
      ],
    },
  };
  private created() {
    this.getUserInfoFun();
  }
  // 获取用户资料
  private getUserInfoFun() {
    GetUserInfo().then((res) => {
      if (res.code === '000') {
        IndexStore.setUserInfo(res.result);
      }
    });
  }
  // 修改密码
  private set(): void {
    this.setPassword.show = true;
  }
  private setDataClear(): void {
    this.setPassword = {
      show: false,
      loading: false,
      data: {
        oPassword: '',
        newPassword: '',
        newPasswords: '',
      },
    };
  }
  private passwordSubmit(): void {
    this.setPassword.loading = true;
    (this.passwordForm as any).validate((valid: boolean) => {
      if (valid) {
        if (this.setPassword.data.newPassword !== this.setPassword.data.newPasswords) {
          this.$message.warning('两次密码要保持一致');
          this.setPassword.loading = false;
          return;
        }
        AdminUpdatePwd({
          password: md5(this.setPassword.data.oPassword),
          newPassword: md5(this.setPassword.data.newPassword),
        }).then((res) => {
          if (res.code === '000') {
            this.$message.success('修改密码成功');
            this.setDataClear();
          }
        });
      } else {
        this.setPassword.loading = false;
      }
    });
  }
  // 退出登录
  private quit(): void {
    this.$confirm('确定要退出吗?', '退出登录', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      Cookies.remove('token', { domain: '.cctaw.cn'})
      LoginAdminLogout().then((resp) => {
        if (resp.code === '000') {
          window.localStorage.setItem('token', '');
          window.localStorage.setItem('user', '');
          window.localStorage.setItem('email', '');
          window.localStorage.setItem('userPermissions', '');
          this.$router.push({ path: '/login' });
        }
      });
    });
  }
}
