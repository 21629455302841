import {
  Descriptions,
  DescriptionsItem,
  Button,
  Loading,
  Input,
  Dialog,
  MessageBox,
  Message,
  Form,
  FormItem,
  Table,
  Tooltip,
  TableColumn,
  Switch,
  Pagination,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Tree,
  Option,
  OptionGroup,
  Radio,
  RadioGroup,
  Select,
  RadioButton,
  Upload,
  Link,
  Tabs,
  TabPane,
  Empty,
  Progress,
  DatePicker,
  TimePicker,
  Row,
  Col,
  Tag,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Image,
  Steps,
  Step,
  Result,
  Cascader,
} from 'element-ui';
const element = {
  install: (Vue: any) => {
    Vue.use(Button);
    Vue.use(Cascader);
    Vue.use(Result);
    Vue.use(Steps);
    Vue.use(Step);
    Vue.use(Tag);
    Vue.use(Descriptions);
    Vue.use(DescriptionsItem);
    Vue.use(Row);
    Vue.use(Col);
    Vue.use(Input);
    Vue.use(Dialog);
    Vue.use(Tooltip);
    Vue.use(Form);
    Vue.use(FormItem);
    Vue.use(Table);
    Vue.use(TableColumn);
    Vue.use(Switch);
    Vue.use(Select);
    Vue.use(Pagination);
    Vue.use(Checkbox);
    Vue.use(CheckboxButton);
    Vue.use(CheckboxGroup);
    Vue.use(Option);
    Vue.use(OptionGroup);
    Vue.use(Tree);
    Vue.use(Radio);
    Vue.use(Progress);
    Vue.use(RadioGroup);
    Vue.use(RadioButton);
    Vue.use(Upload);
    Vue.use(Link);
    Vue.use(Tabs);
    Vue.use(TabPane);
    Vue.use(Empty);
    Vue.use(DatePicker);
    Vue.use(TimePicker);
    Vue.use(Dropdown);
    Vue.use(DropdownMenu);
    Vue.use(DropdownItem);
    Vue.use(Menu);
    Vue.use(Submenu);
    Vue.use(MenuItem);
    Vue.use(MenuItemGroup);
    Vue.use(Image);
    Vue.use(Loading.directive);
    Vue.prototype.$loading = Loading.service;
    Vue.prototype.$msgbox = MessageBox;
    Vue.prototype.$alert = MessageBox.alert;
    Vue.prototype.$confirm = MessageBox.confirm;
    Vue.prototype.$message = Message;
  },
};

export default element;
