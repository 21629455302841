type ResponseType = 1 | 2 | 3 | 4;
export interface Irequest {
  url: string;
  params: any;
  timeout?: number;
  ContentType?: ResponseType;
  replenishData?: any;
  // ContentType?: ResponseType;
}

export interface Iresponse {
  code: string | number;
  result: any;
}

export enum EcontentType {
  'application/x-www-form-urlencoded' = 1, // 原生 form 表单
  'application/json;charset=UTF-8', // 序列化后的 JSON 字符串
  'multipart/form-data', // 消息主体最后以 --boundary-- 标示结束。这种方式一般用来上传文件
  'text/xml', // XML 作为编码方式
}
