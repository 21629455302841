import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from '../views/login/Login.vue';
import Index from '../views/index/Index.vue';
import Cookies from 'js-cookie';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/home/Home.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/login/Register.vue'),
  },
  {
    path: '/retrieve/pwd',
    name: 'Retrieve',
    component: () => import('../views/login/Retrieve.vue'),
  },
  {
    path: '/apply/personage',
    name: 'Personage',
    meta: {
      text: '个人会员入会申请',
    },
    component: () => import('../views/apply/Personage.vue'),
  },
  {
    path: '/apply/unit',
    name: 'UnitApply',
    meta: {
      text: '单位会员入会申请',
    },
    component: () => import('../views/apply/UnitApply.vue'),
  },

  {
    path: '/article/detail',
    name: 'ArticleDetail',
    component: () => import('../views/serve/ArticleDetail.vue'),
  },
  {
    path: '/',
    name: 'Index',
    component: Index,
    children: [
      // 会员端
      { path: '/forum', component: () => import('../views/serve/Forum.vue') },
      { path: '/serve/search', name: 'Search', meta: { text: '会员服务全站搜索' }, component: () => import('../views/serve/Search.vue') },
      {
        path: 'serve',
        name: 'Server',
        meta: {
          text: '会员服务',
        },
        component: () => import('../views/serve/Server.vue'),
      },
      {
        path: 'serve/:list',
        name: 'ArticleList',
        meta: {
          text: '会员服务文章列表',
        },
        component: () => import('../views/serve/ArticleList.vue'),
      },
      {
        path: '/serve/membermien/add',
        name: 'ArticleContribute',
        meta: {
          text: '我要投稿',
        },
        component: () => import('../views/serve/ArticleContribute.vue'),
      },
      {
        path: 'serve/membermien/record',
        name: 'ContributeRecord',
        meta: {
          text: '投稿记录',
        },
        component: () => import('../views/serve/ContributeRecord.vue'),
      },
      // 分支机构端
      {
        path: 'institution/material',
        name: 'Material',
        meta: {
          text: '资讯管理',
        },
        component: () => import('../views/institution/Material.vue'),
      },
       // 
       {
        path: 'institution/ficates',
        name: 'Certificate',
        meta: {
          text: '证书打印',
        },
        component: () => import('../views/institution/Certificate.vue'),
      },
      {
        path: 'institution/safeguard',
        name: 'Safeguard',
        meta: {
          text: '证书打印',
        },
        component: () => import('../views/institution/Safeguard.vue'),
      },
      
      {
        path: 'institution/material/add',
        name: 'MaterialAdd',
        meta: {
          text: '添加资讯',
        },
        component: () => import('../views/institution/MaterialAdd.vue'),
      },
      {
        path: 'institution/report',
        name: 'DataReported',
        meta: {
          text: '资料上报',
        },
        component: () => import('../views/institution/DataReported.vue'),
      },
      {
        path: 'institution/papers',
        name: 'ChangeJie',
        meta: {
          text: '换届申请',
        },
        component: () => import('../views/institution/ChangeJie.vue'),
      },
      {
        path: 'institution/adjustment',
        name: 'ChangeAdjustment',
        meta: {
          text: '调整申请',
        },
        component: () => import('../views/institution/ChangeAdjustment.vue'),
      },
      {
        path: 'institution/change',
        name: 'ChangeChange',
        meta: {
          text: '变更申请',
        },
        component: () => import('../views/institution/ChangeChange.vue'),
      },
      {
        path: 'institution/success',
        name: 'ChangeSuccess',
        meta: {
          text: '申请资料成功',
        },
        component: () => import('../views/institution/ChangeSuccess.vue'),
      },
      {
        path: 'institution/info',
        name: 'Information',
        meta: {
          text: '信息维护',
        },
        component: () => import('../views/institution/Information.vue'),
      },
       {
        path: 'institution/personal',
        name: 'Personal',
        meta: {
          text: '个人信息维护',
        },
        component: () => import('../views/institution/Personal.vue'),
      },
      {
        path: 'institution/membership',
        name: 'Membership',
        meta: {
          text: '会员缴费',
        },
        component: () => import('../views/institution/Membership.vue'),
      },
      {
        path: 'institution/duesa',
        name: 'Duesa',
        meta: {
          text: '会费管理',
        },
        component: () => import('../views/institution/Duesa.vue'),
      },
      {
        path: 'institution/maintenance',
        name: 'Maintenance',
        meta: {
          text: '信息维护',
        },
        component: () => import('../views/institution/Maintenance.vue'),
      },
      {
        path: 'institution/communication',
        name: 'Communication',
        meta: {
          text: '交流讨论',
        },
        component: () => import('../views/institution/Communication.vue'),
      },
      {
        path: 'institution/communication/detail',
        name: 'CommunicationDetail',
        meta: {
          text: '交流讨论详情',
        },
        component: () => import('../views/institution/CommunicationDetail.vue'),
      },
      {
        path: 'institution/communication/add',
        name: 'CommunicationAdd',
        meta: {
          text: '交流讨论详情',
        },
        component: () => import('../views/institution/CommunicationAdd.vue'),
      },
      {
        path: 'institution/communi/detail',
        name: 'CommuniDetail',
        meta: {
          text: '消息详情',
        },
        component: () => import('../views/institution/Communidetil.vue'),
      },
      {
        path: 'institution/member',
        meta: {
          text: '会员管理',
        },
        redirect: '/institution/member/unit'
      },
      {
        path:'institution/member/unitapply',
        name:'UnitMemberApplication',
        meta:{
          text:'单位会员入会申请',
        },
        component: () => import('../views/institution/UnitMemberApplication.vue')
      },
      {
        path: 'institution/member/unitapply/detail',
        name: 'UnitMemberApplicationDetail',
        meta: {
          text: '单位会员入会申请',
        },
        component: () => import('../views/institution/UnitMemberApplicationDetail.vue'),
      },
      {
        path:'institution/member/personageapply',
        name:'PersnoaApplication',
        meta:{
          Text:'个人会员入会申请',
        },
        component: () => import('../views/institution/PersnoaApplication.vue')
      },
      {
        path: 'institution/member/personageapply/detail',
        name: 'PersnoaApplicationDetail',
        meta: {
          text: '个人会员入会申请',
        },
        component: () => import('../views/institution/PersnoaApplicationDetail.vue'),
      },
      {
        path: 'institution/member/unit',
        name: 'UnitMember',
        meta: {
          text: '单位会员管理',
        },
        component: () => import('../views/institution/UnitMember.vue'),
      },
      {
        path: 'institution/member/unit/detail',
        name: 'UnitMemberDetail',
        meta: {
          text: '单位会员管理',
        },
        component: () => import('../views/institution/MemberUnitDetail.vue'),
      },
      {
        path: 'institution/member/personal',
        name: 'PersonalMember',
        meta: {
          text: '个人会员管理',
        },
        component: () => import('../views/institution/PersonalMember.vue'),
      },
      {
        path: 'institution/member/personal/detail',
        name: 'PersonalMemberDetail',
        meta: {
          text: '个人会员管理详情',
        },
        component: () => import('../views/institution/PersonalMemberDetail.vue'),
      },
      {
        path: 'institution/member/change',
        name: 'UnitMemberChange',
        meta: {
          text: '单位代表变更',
        },
        component: () => import('../views/institution/MemberUnitUpdate.vue'),
      },
      {
        path: 'institution/member/change/detail',
        name: 'UnitMemberChangeDetail',
        meta: {
          text: '单位代表变更详情',
        },
        component: () => import('../views/institution/MemberUnitUpdateDetail.vue'),
      },
      {
        path: 'institution/member/apply',
        name: 'UnitMemberApply',
        meta: {
          text: '变更等级申请',
        },
        component: () => import('../views/institution/UnitRepresentativeChange.vue'),
      },
      {
        path: 'institution/member/msg',
        name: 'MemberMsg',
        meta: {
          text: '消息发送',
        },
        component: () => import('../views/institution/MemberMsg.vue'),
      },
      // {
      //   path: 'institution/member/msg/detail',
      //   name: 'MemberMsgDetail',
      //   meta: {
      //     text: '消息发送详情',
      //   },
      //   component: () => import('../views/institution/MemberMsgDetail.vue'),
      // },

      {
        path: 'institution/member/msg/add',
        name: 'MemberMsgAdd',
        meta: {
          text: '添加消息发送',
        },
        component: () => import('../views/institution/MemberMsgAdd.vue'),
      },
      {
        path: 'institution/branch/msg',
        name: 'BranchMsg',
        meta: {
          text: '交流讨论',
        },
        component: () => import('../views/institution/BranchMsg.vue'),
      },
      {
        path: 'institution/branch/msg/add',
        name: 'BranchMsg',
        meta: {
          text: '新增交流讨论',
        },
        component: () => import('../views/institution/BranchMsgAdd.vue'),
      },
      {
        path: 'institution/branch/msg/detail',
        name: 'BranchMsg',
        meta: {
          text: '交流讨论详情',
        },
        component: () => import('../views/institution/BranchMsgDetail.vue'),
      },
      // 消息模块
      {
        path: 'message',
        name: 'Message',
        meta: {
          text: '消息通知',
        },
        component: () => import('../views/message/Message.vue'),
      },
      {
        path: 'message/detail',
        name: 'MessageDeteil',
        meta: {
          text: '消息详情',
        },
        component: () => import('../views/message/MessageDeteil.vue'),
      },
      {
        path: '*',
        name: 'Error',
        component: () => import('../views/error/Error.vue'),
      },
    ],
  },
  {
    path: '*',
    name: 'Error',
    component: () => import('../views/error/Error.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const listUrl = [
    '/home',
    '/login',
    '/register',
    '/retrieve/pwd',
    '/retrieve',
  ];
  if (!listUrl.includes(to.fullPath)) {
    if (!window.localStorage.getItem('token')) {
      router.push({ path: '/login' });
    } else {
      if (!Cookies.get('token')) {
        window.localStorage.clear()
        router.push({ path: '/login' });
      }
    }
  }
  next();
});

export default router;
