













































































































// import { Component, Vue } from "vue-property-decorator";
import { Component, Vue } from 'vue-property-decorator';
import { GetUserInfo } from '@/http/api/LoginApi';
import { IndexStore } from '@/store/module/index';


import "@/assets/style/merge.less";
import Header from "@/components/header/Header.vue";
import MenuTree from "@/components/menuTree/MenuTree.vue"
import PageFooter from '@/components/footer/Footer.vue'

@Component({
  components: {
    Header,
    MenuTree,
    PageFooter
  },
})
export default class Index extends Vue {
  private viewSize: number[] = [];
  get viewHeight() {
    // return `min-height: ${this.viewSize[1] - 52}px;`;
    return `min-height: ${this.viewSize[1] - 150}px;`;
  }
  get posLeft() {
    return `left: ${(this.viewSize[0] - 1200)/2}px;`;
  }
  get bodyLeft() {
    // return `left: ${(this.viewSize[0] - 1200)/2 + 210}px;width:${this.viewSize[0] *0.5 + 390}px`;
    return `width:${this.viewSize[0] *0.5 + 390}px`;
  }
  private left: number = 0;
  private created() {
    const that = this;
    if (that.$route.path == '/') {
      setTimeout(() => {
        console.log(IndexStore.userInfo)
        if (IndexStore.userInfo.userRole == 1) {
          this.$router.push({ path: '/institution/member/unit' })
        } else {
          this.$router.push({ path: '/serve' })
        }
      }, 1);
    }

    
    // that.getUserInfoFun()
    // if (that.$route.path == '/') {
    //   that.getUserInfoFun();
    // }
    
    that.getWindowView();
    // 监听屏幕宽度变化
    window.onresize = () => {
      return (() => {
        that.getWindowView();
      })();
    };
  }
  // 获取用户资料
  private getUserInfoFun() {
    GetUserInfo().then((res) => {
      console.log(res)
      if (res.code === '000') {
      }
    });
  }
  // 获取窗口信息
  private getWindowView() {
    const viewWidth =
      document.body.clientWidth || document.documentElement.clientWidth;
    const viewHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    this.viewSize = [viewWidth, viewHeight];
  }
}
