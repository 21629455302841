import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import { IindexState } from './module/index';

interface IrootsState {
  Index: IindexState;
}

export default new Vuex.Store<IrootsState>({});
