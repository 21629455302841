import instance from './instance';
import { Irequest, Iresponse, EcontentType } from './interface';
import { Message } from 'element-ui';

/**
 * POST 请求
 * @param request
 * @returns
 */
export async function post<T extends Irequest>(request: T): Promise<Iresponse> {
  try {
    const res: any = await instance({
      method: 'post',
      url: request.url,
      data: request.params,
      headers: setHeader(request),
    });
    return setResponse(res);
  } catch (err: any) {
    msgHint(err.message);
    return {
      code: '-1',
      result: '',
    };
  }
}
/**
 * GET 请求
 * @param request
 * @returns
 */
export async function get<T extends Irequest>(request: T): Promise<Iresponse> {
  try {
    const res = await instance.get(request.url, {
      params: request.params,
      headers: setHeader(request),
    });
    return setResponse(res);
  } catch (err: any) {
    msgHint(err.message);
    return {
      code: '-1',
      result: '',
    };
  }
}
/**
 * 下载文件
 * @param url
 * @param params
 * @returns
 */
export async function downloadFile<T extends Irequest>(request: T): Promise<Iresponse> {
  try {
    const res = await instance({
      method: 'post',
      url: request.url,
      data: request.params,
      headers: setHeader(request),
      responseType: 'blob',
    });
    return setResponse(res);
  } catch (err: any) {
    msgHint(err.message);
    return {
      code: '-1',
      result: '',
    };
  }
}
/**
 * 上传文件
 */
export async function uploadFile<T extends Irequest>(request: T): Promise<Iresponse> {
  try {
    const res = await instance({
      method: 'post',
      url: request.url,
      data: request.params,
      headers: setHeader(request),
      // params: request.replenishData,
      onUploadProgress: request.replenishData,

    });
    return setResponse(res);
  } catch (err: any) {
    msgHint(err.message);
    return {
      code: '-1',
      result: '',
    };
  }
}

/**
 * 错误提示
 */
const msgHint = (msg: string): void => {
  Message.error(msg);
};
/**
 * 设置headers
 */
const setHeader = <T extends Irequest>(request: T): any => {
  let num: number = 2;
  if (request.ContentType) {
    num = request.ContentType;
  }
  return {
    'Content-Type': EcontentType[num],
    'Access-Control-Allow-Origin-Type': '*',
    'Authorization': window.localStorage.getItem('token') || '',
  };
};
/**
 * 设置返回值
 * GRT、POST 返回 { code: ***, msg: **, result: *** }
 * 下载文件直接返回res
 */
const setResponse = (res: any): Iresponse => {
  const responseObj: Iresponse = {
    code: '-1',
    result: '',
  };
  if (res.status === 200) {
    const url: string = res.config.url;
    const listUrl = [
      '/system/w/downloadByPath',
      '/large/i/a/lphsHotRoute/exEntity',
      '/large/i/admin/base/exEntity',
      '/member/i/org/member/person/export',//个人会员导出
      '/member/web/pdf/member/person',
      '/member/i/org/member/unit/community/export',//社团性质导出
      '/member/i/org/member/unit/enterprise/export',//企业性质导出
      '/member/web/pdf/member/unit/enterprise',//
      '/member/web/pdf/member/unit/community',
      '/member/web/pdf/unit/reprst',
      '/member/org/pdf/unit/cert/behalf/print', // 【证书打印】机构端单位代表证书
      '/member/org/pdf/unit/cert/print', //         【证书打印】机构端单位证书
    ];
    if (listUrl.includes(url)) {
      responseObj.code = '000';
      responseObj.result = res;
    } else {
      const listCode: string[] = ['000', '1'];
      const { data } = res || '';
      const code = data.code;
      if (listCode.includes(code)) {
        responseObj.code = data.code;
        responseObj.result = data.result;
      } else {
        msgHint(data.msg);
      }
    }
  }
  return responseObj;
};
