import axios, { AxiosRequestConfig, Method } from 'axios';
import router from '@/router';
import { IndexStore } from '@/store/module/index';
import { Message } from 'element-ui';

/**
 * 错误提示
 */
const msgHint = (msg: string): void => {
  Message.error(msg);
};

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status: number, other: string) => {
  // 状态码判断
  switch (status) {
    case 302:
      msgHint(status + ': 接口重定向了!');
      break;
    case 400:
      msgHint(status + ': 发出的请求有错误,服务器没有进行新建或修改数据的操作==>' + status);
      break;
    case 401:
      sessionStorage.clear();
      if (other) {
        // 账号或密码错误
        msgHint(other);
      } else {
        // token过期
        msgHint(status + ': 登录过期,请重新登录');
        if (window.location.pathname !== '/') {
          setTimeout(() => {
            router.replace({
              path: '/',
            });
          }, 1000);
        }
      }
      break;
    // 403 token过期
    // 清除token并跳转登录页
    case 403:
      msgHint(status + ': 服务器拒绝访问');
      break;
    case 404:
      msgHint(status + ': 网络请求不存在');
      break;
    case 405:
      msgHint(status + ': nginx错误');
      break;
    case 406:
      msgHint(status + ': 请求的格式不可得');
      break;
    case 408:
      msgHint(status + ': 请求超时!');
      break;
    case 410:
      msgHint(status + ': 请求的资源被永久删除,且不会再得到的');
      break;
    case 500:
      msgHint(status + ': 服务器发生错误,请检查服务器');
      break;
    case 502:
      msgHint(status + ': 服务器异常');
      break;
    case 503:
      msgHint(status + ': 服务不可用,服务器暂时过载或维护');
      break;
    case 504:
      msgHint(status + ': 网关超时');
      break;
    default:
      msgHint(status + ': 其他错误错误');
  }
};

// 定义接口
interface PendingType {
  url?: string;
  method?: Method;
  params: any;
  data: any;
  cancel: any;
}
// 取消重复请求
const pending: PendingType[] = [];
const CancelToken = axios.CancelToken;
// 移除重复请求
const removePending = (config: AxiosRequestConfig) => {
  // tslint:disable-next-line: forin
  for (const key in pending) {
    const item: number = +key;
    const list: PendingType = pending[key];
    // 当前请求在数组中存在时执行函数体
    // tslint:disable-next-line: max-line-length
    if (list.url === config.url && list.method === config.method && JSON.stringify(list.params) === JSON.stringify(config.params) && JSON.stringify(list.data) === JSON.stringify(config.data)) {
      // 执行取消操作
      list.cancel('操作太频繁,请稍后再试');
      // 从数组中移除记录
      pending.splice(item, 1);
    } 
  }
};

/* 实例化请求配置 */
const instance = axios.create({
  // 请求的base地址
  baseURL: process.env.VUE_APP_BASE_URL,
  // 表示跨域请求时是否需要使用凭证
  withCredentials: true,
});

window.localStorage.setItem('PRE_URL', process.env.VUE_APP_BASE_URL);
// console.log(process.env.VUE_APP_BASE_URL);
/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
  (config) => {
    removePending(config);
    config.cancelToken = new CancelToken((c) => {
      pending.push({ url: config.url, method: config.method, params: config.params, data: config.data, cancel: c });
    })
    return config;
  },
  (error) => {
    msgHint(error.data.error.message);
    return error;
  },
);

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  (response) => {
    removePending(response.config);
    return response;
  },
  // 请求失败
  (error) => {
    if (error.response.status) {
      window.localStorage.clear()
    }
    if (!error.response) {
      return error;
    }
    const { response } = error;
    if (response) {
      errorHandle(response.status, response.data.msg);
      // 超时重新请求
      const config = error.config;
      // 全局的请求次数,请求的间隙
      const [RETRY_COUNT, RETRY_DELAY] = [0, 1000];
      if (config && RETRY_COUNT) {
        // 设置用于跟踪重试计数的变量
        config.__retryCount = config.__retryCount || 0;
        // 检查是否已经把重试的总数用完
        if (config.__retryCount >= RETRY_COUNT) {
          return Promise.reject(response || { message: error.message });
        }
        // 增加重试计数
        config.__retryCount++;
        // 创造新的Promise来处理指数后退
        const backoff = new Promise<void>((resolve) => {
          setTimeout(() => {
            resolve();
          }, RETRY_DELAY || 1);
        });
        // instance重试请求的Promise
        return backoff.then(() => {
          return instance(config);
        });
      }
      return response;
    } else {
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 后续增加断网情况下做的一些操作
      IndexStore.setNetworkState(false);
    }
  },
);

export default instance;
