import { post } from '../request';
import { Iresponse } from '../interface';


// 登陆
export const LoginWebLogin = (params?: any): Promise<Iresponse> => {
  return post({ url: '/system/login', params, ContentType: 1 });
};
// 退出登录
export const LoginAdminLogout = (params?: any): Promise<Iresponse> => {
  return post({ url: '/system/w/logout', params });
};
// 获取图片验证码
export const GetSliderCode = (params?: any): Promise<Iresponse> => {
  return post({ url: '/system/sliderCode/create', params });
};
export const CheckSliderCode = (params?: any): Promise<Iresponse> => {
  return post({ url: '/system/sliderCode/valid', params });
};

// 手机验证码  新的
export const valCodeCreateHY = (params?: any): Promise<Iresponse> => {
  return post({ url: '/system/val/code/mobile/create', params });
};

export const valCodeValidHY = (params?: any): Promise<Iresponse> => {
  return post({ url: '/system/mobile/valCode/valid', params });
};

export const valCodeRetrievePasswordHY = (params?: any): Promise<Iresponse> => {
  return post({ url: '/system/pwd/retrievePassword', params });
};




// 获取手机验证码
export const valCodeCreate = (params?: any): Promise<Iresponse> => {
  return post({ url: '/system/mobile/valCode/create', params });
};

// 验证码验证
export const valCodeValid = (params?: any): Promise<Iresponse> => {
  return post({ url: '/system/val/code/mobile/create', params });
};

// 个人信息维护  修改手机号
export const userUupdate = (params?: any): Promise<Iresponse> => {
  return post({ url: '/system/i/member/user/update', params });
};


// 注册
export const UserRegister = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/web/user/registered', params });
};
// 修改密码
export const AdminUpdatePwd = (params?: any): Promise<Iresponse> => {
  return post({ url: '/system/w/user/pwd/update2', params });
};
// 获取用户资料
export const GetUserInfo = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/user/info', params });
};
// 获取目录树
export const GetMenuTree = (params?: any): Promise<Iresponse> => {
  return post({ url: '/system/w/user/viewMenu/list', params });
}
