


















































interface IuserInfo {
  code: string | object[];
  codes: string | object[];
  account: string | object[];
  password: string | object[];
}
import { Component, Vue, Ref } from 'vue-property-decorator';
import { LoginWebLogin } from '@/http/api/LoginApi';

import Verificate from '@c/verificate/Verificate.vue';
import md5 from 'js-md5';
// import VueCookie from 'js-cookie';
import Cookies from 'js-cookie';

import Qs from 'qs';
import { Base64 } from 'js-base64';

const validatorrlevelOrOpinion = (rule: any, value: any, callback: any) => {
    if (value == '') {
      callback(new Error('请输入统一社会信用代码'))
    } else {
      if (value.length == 11) {
        callback(new Error('统一社会信用代码格式错误'))
      } else {
        callback()
      }
    }
  }

@Component({
  components: {
    Verificate,
  },
})
export default class Login extends Vue {
  @Ref() private userfrom!: HTMLDListElement;
  private loading: boolean = false;
  private userInfo: IuserInfo = {
    code: '',
    codes: '',
    account: '',
    password: '',
  };
  private rules: IuserInfo = {
    code: [
      { required: true, message: '请输入手机号', trigger: ['blur', 'change'] },
      { pattern: /^1[3456789]\d{9}$/, message: '手机号格式错误', trigger: ['blur', 'change'] }
    ],
    codes: [
      { required: true, message: '请输入分支机代码', trigger: 'blur' },
    ],
    // account: [{ required: true, validator: validatorrlevelOrOpinion, trigger: ['blur'] }],
    account: [
      { required: true, message: '请输入统一社会信用代码', trigger: ['blur'] },
      // { pattern:/[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/, message:'统一社会信用代码格式错误' }
      { pattern:/^.{18,}$/, message:'统一社会信用代码格式错误', trigger: ['blur', 'change'] }
    ],
    password: [
      { required: true, message: '请输入密码', trigger: 'blur' },
      { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/, message: '8-16位数字字母组合', trigger: 'blur' },
    ],
  };
  private showCode: boolean = false;
  private mounted() {
    const that = this;
    document.onkeydown = (event: any): void => {
      if (event.which === 13) {
        that.handleSubmit();
      }
    };
  }
  private beforeDestroy() {
    document.onkeydown = null;
  }
  private handleSubmit() {
    this.loading = true;
    (this.userfrom as any).validate((valid: boolean) => {
      this.loading = false;
      if (valid) {
        this.showCode = true;
      }
    });
  }
  private successVer() {
    this.showCode = false;
    this.userPwsLogin();
  }

  private userPwsLogin() {
    let token = ''
    if (this.tabPaneVal == '1') {
      token = 'Basic ' + Base64.encode(this.userInfo.account + ':' + md5(this.userInfo.password));
    } else if (this.tabPaneVal == '2') {
      token = 'Basic ' + Base64.encode(this.userInfo.code + ':' + md5(this.userInfo.password));
    } else {
      token = 'Basic ' + Base64.encode(this.userInfo.codes + ':' + md5(this.userInfo.password));
    }
    window.localStorage.setItem('token', token);
    
    this.loading = true;
    LoginWebLogin(Qs.stringify({ ltype: 'web' })).then(res => {
      this.loading = false;
      console.log(res);
      
      if (res.code === '000') {
        this.$message.success('登录成功');
        window.localStorage.setItem('token', res.result.tokenType + ' ' + res.result.accessToken);
        Cookies.set('token', res.result.accessToken, { expires: 7, domain: '.cctaw.cn' })
        if (res.result.user.userType == 11) {
          return this.$router.push({ path: '/institution/member/unit' })
        }
        this.$router.push({ path: '/serve' });
        // this.$router.push({ path: '/institution/member/unit' });
        // this.$router.push({ path: '/apply/unit' });
      }
    });
  }
  private cancleVer() {
    this.showCode = false;
  }
  // 注册 找回密码
  private handleJump(index: number) {
    if (index === 1) {
      this.$router.push({
        path: '/retrieve/pwd',
      });
    } else if (index === 2) {
      this.$router.push({
        path: '/register',
      });
    }
  }
  private tabPaneVal: any = '1';
  private created() {
    // Cookies.set('name', 'value', { expires: 7 })
    // Cookies.set('www', '1111', { expires: 7, domain: '' })
    // this.tabPaneVal = this.$route.query.type || '1';
    // setTimeout(() => {
    //   (this.$refs.userfrom as any).resetFields()
    // }, 1000)
  }
  private handleInput() {
    (this.$refs.userfrom as any).resetFields()
  }
  private tabTurn (type: any) {
    this.tabPaneVal = type || '1';
    (this.$refs.userfrom as any).resetFields()
  }
}
