<template>
    <div>
        <ul class="menu">
            <template v-for="item in menu" class="menu-item">
                <!-- <li class="menu-item no-child-item"  v-if="!item.children || !item.children.length" :key="item.id">
                    <router-link :to="item.href || '/404/'+item.id" class="no-child-item-link" :class="openMenuId == item.id ? 'active': ''" @click.native="menuClick(item.id)">{{item.name}}</router-link>
                </li>
                <li class="menu-item has-child-item" v-else :key="item.id">
                    <div class="child-item-link" :class="openMenuId == item.id ? 'active': ''" @click="menuClick(item.id)">{{item.name}}</div>
                    <ul class="sub-menu"  :style="openMenuId == item.id ? `height: ${item.children.length * 40}px`: 'height:0px'">
                        <li v-for="sitem in item.children" :key="sitem.id" class="sub-menu-item">
                            <router-link :to="sitem.href  || '/404'+item.id" class="sub-menu-link">{{sitem.name}}</router-link>
                        </li>
                    </ul>
                </li> -->
                <li class="menu-item has-child-item" :key="item.id">
                    <router-link :to="item.href || '/404/'+item.id" class="no-child-item-link"  @click.native="menuClick(item.id)">
                        {{item.name}}
                        <img v-if="item.children && item.children.length" class="menu-more" src="../../assets/images/more.png" />
                    </router-link>

                    <template v-if="item.children && item.children.length">
                        <ul class="sub-menu"  >
                            <li v-for="sitem in item.children" :key="sitem.id" class="sub-menu-item">
                                <router-link :to="sitem.href  || '/404'+item.id" class="sub-menu-link">{{sitem.name}}</router-link>
                            </li>
                        </ul>
                    </template>
                </li>
            </template>
        </ul>
    </div>
</template>
<script>
import {GetMenuTree} from '@/http/api/LoginApi'
import MenuTree from '@/components/menuTree/MenuTree.vue'
export default {
    components: {
        MenuTree
    },
    data () {
        return {
            openMenuId: '',
            menu: []
            // menu: [{name: '会员管理', id: 1, href:"/home/home1",children: []},
            // {name: '会员管理1', id: 2, href:"",children: [{name: '会员管理21', id: 21, href:"/home/1"},{name: '会员管理二二', id: 22, herf:"/home/2"},{name: '会员管理二三', id: 23, herf:"/home/3"}]},
            // {name: '会员管理2', id: 3, href:"/home/home3",children: []}]
        }
    },
    created () {
        this.getList()
    },
    methods: {
        getList () {
            GetMenuTree({type: "back_manager"}).then(resp => {
                if (resp.code == '000') {
                    this.menu = resp.result  
                } 
            })
        },
        menuClick (id) {
            this.openMenuId = id
        }   
    }
}
</script>
<style>

</style>

<style lang="less" scoped>
.menu-item {
    width: 100%;
    position: relative;
    .no-child-item {
        width: 100%;
    }
    .no-child-item-link,.sub-menu-link {
        display: block;
    }
    .no-child-item-link,.child-item-link,.sub-menu-link {
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        font-weight: 500;
        color: #626262;
        border-bottom: 1px solid  #E0E0E0;
        padding: 0px 10px 0 25px;
    }
    .sub-menu-link {
        padding-left: 35px;
        font-size: 14px;
    }
}
.no-child-item .active,
.no-child-item .router-link-exact-active,
.no-child-item .router-link-active
 {
    background: url("../../assets/images/menu-bg.png") no-repeat;
    background-size: 100% 100%;
    // background-position: 0px 4px;
    color: #fff!important;
}
.has-child-item .active,
.has-child-item .no-child-item-link.router-link-exact-active,
.has-child-item .no-child-item-link.router-link-active
 {
    background: url("../../assets/images/menu-bg.png") no-repeat;
    background-size: 100% 100%;
    // background-position: 0px 4px;
    color: #fff!important;
}
.sub-menu {
    opacity: 0;
    height: 0;
    max-height: 0px;
    overflow: hidden;
    transition: all .5s linear;
    // display: none;
}
.no-child-item-link.router-link-active+.sub-menu {
    // display: block;
    opacity: 1;
    height: auto;
    max-height: 400px;
    // transition: all .5s ease-in-out;
}
.sub-menu .router-link-exact-active,
.sub-menu .router-link-active{
    color:#1E63B0;
    position: relative;
}
.sub-menu .router-link-exact-active::after,
.sub-menu .router-link-active::after {
    content: '';
    position: absolute;
    top: 12px;
    left: 20px;
    width: 3px;
    height: 16px;
    background: #1E63B0;
    border-radius: 2px;
}

.img-box {
    width: 200px;
    height: 40px;
    position: absolute;
    left: -5px;
    top: 0px;
    img {
        width: 100%;
        height: 100%;
    }
}
.child-item-link, .no-child-item-link {
    position: relative;
    z-index: 100;
}
.menu-more {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 12px;
    right: 5px;
    transition: all 0.5;
}
.router-link-active .menu-more {
    transform:rotate(90deg);
}

</style>

