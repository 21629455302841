import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '../index';

export interface IindexState {
  userInfo: any; // 用户资料（对象）
  networkState: boolean; // 网络状态（是否断网）
}

@Module({
  name: 'Index',
  dynamic: true,
  store,
})
export default class Index extends VuexModule implements IindexState {
  public userInfo: any = '';
  public networkState: boolean = true;

  // get getUserInfo(): any {
  //   return this.userInfo;
  // }
  @Mutation
  public setUserInfo(val: any) {
    this.userInfo = val;
  }
  @Mutation
  public setNetworkState(val: boolean) {
    this.networkState = val;
  }
}

export const IndexStore =  getModule(Index);
